import type { DeepPartial, DotNotatedPropertyAccess } from "@/global"
import { hash } from "@/utils/data"
import { isBrowser } from "@/utils/env"
import { assign, get } from "radash"
import { useMemo } from "react"

import sanitizeHtml from "sanitize-html"

export const fallbackLocale = "de"
export const getSupportedRegionCodes = (locale: string) =>
  ({ de: ["DE"] })[locale]
export const getFallbackLocaleWithRegion = () =>
  `${fallbackLocale}-${getSupportedRegionCodes(fallbackLocale)?.at(0)}`

export type I18nLocaleTextBundle<Bundle extends object | string = object> = {
  [Key in keyof Bundle]: Bundle[Key] extends object
    ? I18nLocaleTextBundle<Bundle[Key]>
    : string
}

const warnedOnce: Record<string, boolean> = {}

const _applyVariables = (
  translation: string,
  variables: Record<string, string | number>,
) => {
  if (!variables) return translation
  return Object.entries(variables ?? {}).reduce(
    (translation, [variable, value]) =>
      translation.replaceAll(`{{${variable}}}`, `${value}`),
    translation,
  )
}

export type PluralExtensions = "_one" | "_other"

export type ExistsFunction<T extends I18nLocaleTextBundle> = (
  key: string,
) => boolean

function _exists<T extends I18nLocaleTextBundle>(messages: T) {
  const existsFunction: ExistsFunction<T> = (key: string) => {
    const translation = get(messages, key, undefined)

    return !!translation
  }

  return existsFunction
}

export type TranslateFunction<
  T extends I18nLocaleTextBundle,
  K extends keyof DotNotatedPropertyAccess<
    T,
    PluralExtensions
  > = keyof DotNotatedPropertyAccess<T, PluralExtensions>,
> = (key: K | K[], variables?: Record<string, string | number>) => string

function _translate<
  T extends I18nLocaleTextBundle,
  K extends keyof DotNotatedPropertyAccess<
    T,
    PluralExtensions
  > = keyof DotNotatedPropertyAccess<T, PluralExtensions>,
>(messages: T, overwriteMessages?: DeepPartial<T>) {
  const translateFunction: TranslateFunction<T, K> = (
    key: K | K[],
    variables: Record<string, string | number> = {},
  ) => {
    const keySequence = Array.isArray(key) ? key : [key]
    const mergedMessages = overwriteMessages
      ? assign(messages, overwriteMessages)
      : messages

    for (const key of keySequence) {
      const pluralVariable = "count" in variables

      // check if plural key exists
      if (pluralVariable && Number(variables.count) === 1) {
        const translationOne = get(mergedMessages, `${key}_one`, undefined)

        if (translationOne) {
          return _applyVariables(translationOne, variables)
        }
      } else if (pluralVariable && Number(variables.count) !== 1) {
        const translationOther = get(mergedMessages, `${key}_other`, undefined)

        if (translationOther) {
          return _applyVariables(translationOther, variables)
        }
      }

      const translation = get(mergedMessages, key, undefined)

      if (!translation || typeof translation !== "string") {
        // if key is not the last key in the sequence, continue with next key
        const isLastKeyInSequence = key === keySequence[keySequence.length - 1]
        if (!isLastKeyInSequence) continue

        // print missing translation
        if (!warnedOnce[key] && isBrowser()) {
          console.warn(`😱 Missing translation for ${key}`)
          warnedOnce[key] = true
        }
        return key
      }

      return _applyVariables(translation, variables)
    }

    // print missing translation
    if (isBrowser()) {
      console.error("😱 No translation keys passed to translate function.")
    }
    return ""
  }

  return translateFunction
}

// biome-ignore lint/suspicious/noExplicitAny: required to be agnostic
const _tHtml = <T extends (...args: any[]) => string>(t: T) => {
  const tHtml = (...args: Parameters<typeof t>) => {
    const message = t(...args)

    const cleanedHtml = sanitizeHtml(message, {
      allowedTags: ["kbd", "code", "b", "i", "strong", "a", "br"],
      allowedAttributes: {
        a: ["href", "target"],
      },
    })

    return (
      <span
        key={hash(cleanedHtml)}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: safe as we sanitize the html
        dangerouslySetInnerHTML={{ __html: cleanedHtml }}
      />
    )
  }
  tHtml.displayName = "tHtml"

  return tHtml
}

/**
 * to be used in client components
 */
export const useTranslation = <T extends I18nLocaleTextBundle>(
  messages: T,
  overwriteMessages?: DeepPartial<T>,
) => {
  const t = useMemo(
    () => _translate(messages, overwriteMessages),
    [messages, overwriteMessages],
  )
  const tHtml = useMemo(() => _tHtml(t), [t])
  const exists = useMemo(() => _exists(messages), [messages])

  return { t, tHtml, exists }
}

/**
 * to be used in utility functions
 */
export const getTranslation = <T extends I18nLocaleTextBundle>(
  messages: T,
  overwriteMessages?: DeepPartial<T>,
) => {
  const t = _translate(messages, overwriteMessages)
  const tHtml = _tHtml(t)
  const exists = _exists(messages)

  return { t, tHtml, exists }
}

/**
 * to be used in server components
 */
export const serverTranslation = async <T extends I18nLocaleTextBundle>(
  messages: T,
  overwriteMessages?: DeepPartial<T>,
) => {
  const t = _translate(messages, overwriteMessages)
  const tHtml = _tHtml(t)
  const exists = _exists(messages)

  return { t, tHtml, exists }
}
